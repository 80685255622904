/* eslint-disable import/prefer-default-export */
export const waGratis1 = [
  {
    id: 1,
    text: 'Customer mungkin <b>lupa menyiapkan</b> atau <b>membawa uang</b> saat melakukan pembayaran COD.',
    check: false,
  },
  {
    id: 2,
    text: 'Customer yang tidak menerima <b>informasi paket</b> mungkin <b>beralih</b> ke <b>penjual lain.</b>',
    check: false,
  },
  {
    id: 3,
    text: '<b>Pusing</b> dan <b>ribet</b> ketika update notif <b>manual</b> ke banyak customer.',
    check: false,
  },
]
export const waGratis2 = [
  {
    id: 1,
    text: 'Tanpa <b>broadcast,</b> tingkat <b>order ulang</b> rendah.',
    check: false,
  },
  {
    id: 2,
    text: '<b>Ribet,</b> untuk <b>chat manual</b> ke banyak customer.',
    check: false,
  },
]
export const waPremium1 = [
  {
    id: 1,
    text: '<b>Notifikasi</b> ke customer <b>otomatis</b> saat paket sampai kota customer.',
    check: true,
  },
  {
    id: 2,
    text: '<b>Kirim resi otomatis</b>  ke customer saat produk sudah di pickup.',
    check: true,
  },
  {
    id: 3,
    text: 'Buat <b>template pesan</b> sesuai <b>kebutuhanmu.</b>',
    check: true,
  },
]
export const waPremium2 = [
  {
    id: 1,
    text: 'Tingkat <b>penjualan</b> dan <b>order ulang</b> dengan <b>broadcast.</b>',
    check: true,
  },
  {
    id: 2,
    text: 'Kirim banyak pesan <b>dengan 1x klik.</b>',
    check: true,
  },
]
